var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"recipes"},[_c('h2',{staticClass:"recipes__title"},[_vm._v(_vm._s(_vm.$t("recipes.title")))]),_c('h3',{staticClass:"recipes__descr"},[_vm._v(" "+_vm._s(_vm.$t("recipes.tagLine"))+" ")]),_vm._l((_vm.$t('recipes.recipes')),function(recipe,index){return _c('div',{key:index,staticClass:"recipes__card-container"},[_c('transition',{attrs:{"name":"flip","mode":"out-in"}},[(!recipe.flipped)?_c('div',{key:"card",staticClass:"card"},[_c('div',{staticClass:"card-img"},[_c('img',{attrs:{"src":require(("@/assets/img/" + (recipe.img))),"alt":""}})]),_c('div',{staticClass:"recipe"},[_c('h3',{staticClass:"recipe__title"},[_vm._v(_vm._s(recipe.name))]),_c('div',{staticClass:"recipe__description item"},[_vm._v(" "+_vm._s(recipe.description)+" ")]),_c('div',{staticClass:"recipe__stats item"},[_c('p',[_vm._v(_vm._s(_vm.$t("recipes.calories"))+": "+_vm._s(recipe.calories))]),_c('p',[_vm._v("|")]),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.prepTime"))+": "+_vm._s(recipe.duration)+" Min")]),_c('p',[_vm._v("|")]),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.servingSize"))+": "+_vm._s(recipe.serving_size))])]),_c('div',{staticClass:"recipe__list"},[_c('p',{staticClass:"recipe__list__text"},[_vm._v(" "+_vm._s(_vm.$t("recipes.ingredients"))+" : ")]),_c('div',{staticClass:"recipe__list__lists"},[_c('ul',_vm._l((recipe.ingredients.slice(
                    0,
                    recipe.ingredients.length / 2
                  )),function(ingredient,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(ingredient.amount)+" "+_vm._s(ingredient.name)+" ")])}),0),_c('ul',_vm._l((recipe.ingredients.slice(
                    recipe.ingredients.length / 2,
                    recipe.ingredients.length - 1
                  )),function(ingredient,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(ingredient.amount)+" "+_vm._s(ingredient.name)+" ")])}),0)])]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.toggleCard(recipe)}}},[_vm._v(" "+_vm._s(_vm.$t("recipes.frontButton"))+" ")])])]):_c('div',{key:"back",staticClass:"card reverse"},[_c('div',{staticClass:"card-img"},[_c('div',{staticClass:"legend"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"color",attrs:{"id":"red"}}),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.fats")))])]),_c('div',{staticClass:"label"},[_c('div',{staticClass:"color",attrs:{"id":"blue"}}),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.carbs")))])]),_c('div',{staticClass:"label"},[_c('div',{staticClass:"color",attrs:{"id":"green"}}),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.proteins")))])])]),_c('img',{staticClass:"chart",attrs:{"src":require("../assets/img/shrimp_chart.png"),"alt":""}})]),_c('div',{staticClass:"recipe"},[_c('h3',{staticClass:"recipe__title"},[_vm._v(_vm._s(recipe.name))]),_c('div',{staticClass:"recipe__description item"},[_vm._v(" "+_vm._s(recipe.description)+" ")]),_c('div',{staticClass:"recipe__stats item"},[_c('p',[_vm._v(_vm._s(_vm.$t("recipes.calories"))+": "+_vm._s(recipe.calories))]),_c('p',[_vm._v("|")]),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.prepTime"))+": "+_vm._s(recipe.duration)+" Min")]),_c('p',[_vm._v("|")]),_c('p',[_vm._v(_vm._s(_vm.$t("recipes.servingSize"))+": "+_vm._s(recipe.serving_size))])]),_c('div',{staticClass:"recipe__list"},[_c('p',{staticClass:"recipe__list__text"},[_vm._v(_vm._s(_vm.$t("recipes.steps"))+":")]),_c('div',{staticClass:"recipe__list__lists flex-column"},[_c('ol',_vm._l((recipe.steps.slice(
                    0,
                    recipe.steps.length / 2
                  )),function(step,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(step)+" ")])}),0),_c('ol',{attrs:{"start":recipe.steps.length / 2 + 1}},_vm._l((recipe.steps.slice(
                    recipe.steps.length / 2,
                    recipe.steps.length - 1
                  )),function(step,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(step)+" ")])}),0)])]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.toggleCard(recipe)}}},[_vm._v(" "+_vm._s(_vm.$t("recipes.backButton"))+" ")])])])])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }